(function (w, d) {

    let homeAnimations = {

        init: function () {

            const header = document.querySelector('.main-header'),
                heroContent = document.querySelector('.fade-in-section'),

                heroScrollTimeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: '#wrap',
                        start: 'top top',
                        endTrigger: '.intro', // if using the original scrolling reveal, change this to endTrigger: ".scrollSection"
                        pin: true,
                        scrub: 0.5,
                        onLeave: function(self) {
                            self.disable()
                            self.scroll(0)
                            self.animation.progress(1)
                        }
                    }
                });

            // ZOOM REVEAL
            heroScrollTimeline.to('.heroImage', 1, {scale: 90, xPercent:700, yPercent: 900, ease: "power2.in"})
              .to('.intro', .5, {autoAlpha: 0, }, '-=.5')
              .to([header, heroContent], .5, {autoAlpha: 1}, '-=.5')
              .set('.heroImage', {scale:0, xPercent:0, yPercent: 0})
              .set('#wrap', {clearProps:"transform"})

            /* ORIGINAL SCROLLING REVEAL
            heroScrollTimeline.to('.scrollSection', 1, {yPercent: -100})
              .to('.scrollSection', .5, {autoAlpha: 0, }, '-=.7')
              .to([header, heroContent], .3, {autoAlpha: 1}, '-=.3')*/

        }

    }

    w.addEventListener('load', loadEvents);

    function loadEvents() {
        if (Modernizr.es6object) {
            homeAnimations.init();
        }
    }

})(window, document);
